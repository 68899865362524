$orange:#faa61a;
$orange2:#f6c403;
$dark:#100c1c;
$white:#ffffff;
$font_grey:#231f20;

$font_color1:#100c1c;
$font_color2:#a4a4b4;

$main_font:'Muli', sans-serif;

$grey:#f7f7f7;

@mixin fill{
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
}

body{
	font-family: $main_font;
	ul{
		margin: 0;
		padding: 0;
		list-style: none;
	}
	h1,h2,h3,h4,h5,h6,p,ul,ol,a{
		margin: 0;
	}
	a{
		text-decoration: none;
		&:hover{
			text-decoration: none;
		}
	}
}

.container2 {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .container2 {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container2 {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container2 {
    width: 1170px;
  }
}
@media (min-width: 1600px) {
	.container2 {
	  width: 1330px;
	}
  }
@media (min-width: 1700px) {
  .container2 {
    width: 1510px;
  }
}
a.all_click{
	@include fill;
}

a.look_more{
	display: inline-block;
	font-size: 13px;
	line-height: 13px;
	color: $dark;
	padding-right: 34px;
	min-height: 28px;
	background-position: right center;
	background-repeat: no-repeat;
	background-image: url('./../img/news_more_arr.png');
	text-transform: uppercase;
	padding-top: 8px;
	strong{
		font-weight: 800;
	}
	&.w{
		color: $white;
	}
}

div.vertical_height{
	@include fill;
	> div{
		height: 100%;
		width: 100%;
		display: table;
		> div{
			display: table-cell;
			vertical-align: middle;
			height: 100%;
		}
	}
}

div.default_font{
	font-size: 13px;
	line-height: 30px;
	font-weight: 600;
	color: $dark;
	@media only screen and (max-width: 1200px) {
		font-size: 12px;
		line-height: 29px;
	}
	h1{
		font-size: 60px;
		line-height: 70px;
		@media only screen and (max-width: 1500px) {
			font-size: 36px;
			line-height: 48px;
		}
		@media only screen and (max-width: 1200px) {
			font-size: 30px;
			line-height: 45px;
		}
	}
	h2{
		font-size: 36px;
		line-height: 48px;
		@media only screen and (max-width: 1500px) {
			font-size: 30px;
			line-height: 45px;
		}
		@media only screen and (max-width: 1200px) {
			font-size: 24px;
			line-height: 34px;
		}
	}
	h3{
		font-size: 30px;
		line-height: 45px;
		@media only screen and (max-width: 1500px) {
			font-size: 24px;
			line-height: 34px;
		}
		@media only screen and (max-width: 1200px) {
			font-size: 21px;
			line-height: 31px;
		}
	}
	h4{
		font-size: 24px;
		line-height: 34px;
		@media only screen and (max-width: 1500px) {
			font-size: 21px;
			line-height: 31px;
		}
		@media only screen and (max-width: 1200px) {
			font-size: 18px;
			line-height: 28px;
		}
	}
	h5{
		font-size: 21px;
		line-height: 31px;
		@media only screen and (max-width: 1500px) {
			font-size: 18px;
			line-height: 28px;
		}
		@media only screen and (max-width: 1200px) {
			font-size: 15px;
			line-height: 15px;
		}
	}
	h6{
		font-size: 18px;
		line-height: 28px;
		@media only screen and (max-width: 1500px) {
			font-size: 15px;
			line-height: 15px;
		}
		@media only screen and (max-width: 1200px) {
			font-size: 13px;
			line-height: 23px;
		}
	}
	p{
		font-weight: inherit;
	}
	h1,h2,h3,h4,h5,h6,p{
		color: inherit;
	}
	a{
		color: $orange;
	}
	strong{
		font-weight: 700;
		font-family: $main_font;
	}
}

div.text_desc{
	div.default_font{
		h1,h2,h3,h4,h5,h6,p,ul,ol,a{
			margin-bottom: 20px;
			> a{
				margin-bottom: 0;
			}
			&:last-child{
				margin-bottom: 0;
			}
		}
	}
	a{
		margin-bottom: 20px;
		display: inline-block;
	}
	strong{
		font-weight: 700;
		font-family: $main_font;
	}
	ul{
		padding-left: 20px;
		list-style: disc;
		li{
			color: $orange;
			padding-bottom: 5px;
			&:last-child{
				padding-bottom: 0;
			}
		}
	}
	ol{
		padding-left: 20px;
		li{
			color: $orange;
			padding-bottom: 5px;
			&:last-child{
				padding-bottom: 0;
			}
		}
	}
}

div.page{
	position: relative;
	overflow: hidden;
}

header.header{
	transition: 0.3s all ease;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	padding-top: 70px;
	z-index: 15;
	@media only screen and (max-width: 1500px) {
		padding-top: 45px;
	}
	@media only screen and (max-width: 991px) {
		padding: 15px 0 0 0;
	}
	div.logo_box{
		position: relative;
		img{
			max-width: 100%;
			height: auto;
			@media only screen and (max-width: 991px) {
				max-width: 100px;
			}
		}
		div.rwd_button{
			width: 30px;
			position: absolute;
			top: 5px;
			right: 0;
			display: none;
			@media only screen and (max-width: 991px) {
				display: block;
			}
			> div{
				width: 100%;
				height: 3px;
				margin-bottom: 3px;
				&:last-child{
					margin-bottom: 0;
				}
				background: $orange;
			}
		}
	}
	div.menu_box{
		padding-top: 21px;
		text-align: center;
		@media only screen and (max-width: 1700px) {
			padding-top: 17px;
		}
		@media only screen and (max-width: 1600px) {
			text-align: left;
			padding-left: 25px;
			padding-top: 11px;
		}
		@media only screen and (max-width: 991px) {
			background:$grey;
			padding-left: 0;
			padding-top: 1px;
			padding-bottom: 1px;
			margin: 0 -15px;
			margin-top: 15px;
			display: none;
			nav.menu{
				ul{
					li{
						&:last-child{
							div{
								a{
									border-bottom: none;
								}
							}
						}
						div{
							&.on{
								a{
									color: $orange !important;
									&:after{
										display: none !important;
									}
								}
							}
							a{
								padding: 10px 0;
								display: block;
								border-bottom: 1px solid $dark;
							}
						}
					}
				}
			}
		}
	}
	div.phone_box{
		.pb{
			width: 267px;
			margin-left: auto;
			color: $font_color2;
			font-size: 11px;
			line-height: 21px;
			font-weight: 700;
			padding-left: 67px;
			min-height: 60px;
			background-repeat: no-repeat;
			background-position: left top;
			background-image: url('./../img/phone_icon_color.png');
			@media only screen and (max-width: 1500px) {
				min-height: 46px;
				background-size: auto 100%;
				padding-left: 57px;
				margin-left: 10px;
			}
			span{
				display: block;
				padding-top: 5px;
				font-size: 24px;
				line-height: 24px;
				font-weight: 800;
				color: $white;
				@media only screen and (max-width: 1500px) {
					font-size: 17px;
					line-height: 17px;
				}
				p{
					a{
						color: $white;
					}
				}
			}
		}
		> ul{
			display: inline-block;
			vertical-align: middle;
			text-align: right;
			&.social{
				display: none;
			}
			> li{
				display: inline-block;
				vertical-align: bottom;
				div.default_font{
					line-height: 28px;
					span{
						font-size: 18px;
						line-height: 18px;
					}
					h3{
						line-height: 36px;
					}
				}
				&:first-child{
					margin-right: 17px;
					div.default_font{
						color: $white;
					}
				}
				&:last-child{
					div.default_font{
						color: $orange;
						font-family: $main_font;
					}
				}
			}
		}
		@media only screen and (max-width: 991px) {
			display: none;
		}
	}
}

nav.menu{
	> ul{
		> li{
			display: inline-block;
			vertical-align: top;
			margin-right: 50px;
			@media only screen and (max-width: 1600px) {
				margin-right: 25px;
			}
			@media only screen and (max-width: 991px) {
				width: 100%;
				text-align: center;
			}
			&:last-child{
				margin-right: 0;
				 a{
					padding-right: 0;
					margin-right: 0;
					&:after{
						display: none;
					}
				}
			}
			div.on{
				a{
					color: $white !important;
					&:after{
						width: 100%;
						left: 0;
					}
				}
			}
			a{
				color: $font_color2;
				font-size: 13px;
				line-height: 13px;
				text-decoration: none;
				position: relative;
				transition: 0.3s all ease;
				font-weight: 700;
				position: relative;
				@media only screen and (max-width: 1200px) {
					font-size: 11px;
					line-height: 11px;
				}
				@media only screen and (max-width: 991px) {
					color: $dark;
				}
				&:after{
					content: '';
					border-bottom: 1px solid $white;
					position: absolute;
					left: 50%;
					width: 0;
					top: 100%;
					transition: 0.3s all ease;
				}
				&:hover{
					color: $white !important;
					&:after{
						width: 100%;
						left: 0;
					}
				}
			}
		}
		li.on{
			a{
				color: $orange;
			}
		}
	}
}

section.slider{
	width: 100%;
	position: relative;
	background-color: $dark;
	div.cover{
		@include fill;
		background-repeat: no-repeat;
		background-position: center top;
		background-image: url('./../img/nakladka.png');
		z-index: 2;
		background-size: cover;
	}
	> ul{
		position: relative;
		top: 0;
		left: 0;
		width: 100%;
		height: 76vh;
		@media only screen and (max-width: 991px) {
			height: 67vh;
		}
		> li{
			@include fill;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;
			div.slider_content{
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				z-index: 10;
				div.slider_desc{
					@media only screen and (max-width: 1700px) {
						padding-left: 70px;
					}
					@media only screen and (max-width: 1600px) {
						padding-left: 60px;
					}
					@media only screen and (max-width: 1290px) {
						padding-left: 0;
					}
					@media only screen and (max-width: 991px) {
						padding-left: 0;
						text-align: center;
					}
					.default_font{
						color: $font_color2;
						font-size: 21px;
						line-height: 31px;
						text-transform: uppercase;
						font-weight: 600;
						@media only screen and (max-width: 1500px) {
							font-size: 17px;
							line-height: 27px;
						}
						@media only screen and (max-width: 1200px) {
							font-size: 15px;
							line-height: 25px;
						}
						@media only screen and (max-width: 991px) {
							font-size: 13px;
							line-height: 23px;
						}
						p{
							font-weight: inherit;
						}
						h1{
							color: $white;
							font-weight: 800;
						}
					}
					p.more{
						margin-top: 30px;
						padding-top: 30px;
						font-size: 15px;
						line-height: 15px;
						padding-right: 65px;
						background-repeat: no-repeat;
						background-position: center right;
						background-image: url('./../img/arr123.png');
						min-height: 75px;
						text-transform: uppercase;
						color: $white;
						display: inline-block;
						font-weight: 800;
						@media only screen and (max-width: 1200px) {
							font-size: 13px;
							line-height: 13px;
							background-size: auto 100%;
							min-height: 35px;
							padding-top: 12px;
							padding-right: 35px;
							margin-top: 15px;
						}
					}
				}
			}
		}
	}
	> .learn_more{
		background-color: $dark;
		position: relative;
		height: 24vh;
		@media only screen and (max-width: 991px) {
			height: 33vh;
		}
		div.desc{
			text-align: center;
			position: relative;
			z-index: 4;
			font-size: 15px;
			line-height: 30px;
			color: $font_color2;
			font-weight: 600;
			@media only screen and (max-width: 1200px) {
				font-size: 12px;
				line-height: 29px;
			}
			> div{
				margin-bottom: 20px;
				@media only screen and (max-width: 1500px) {
					margin-bottom: 10px;
				}
				@media only screen and (max-width: 1200px) {
					margin-bottom: 0;
				}
			}
			p{
				font-weight: inherit;
				text-transform: uppercase;
			}
			h1,h2,h3,h4,h5,h6{
				color: $white;
				font-size: 36px;
				line-height: 48px;
				font-weight: 800;
				@media only screen and (max-width: 1500px) {
					font-size: 30px;
					line-height: 45px;
				}
				@media only screen and (max-width: 1200px) {
					font-size: 24px;
					line-height: 34px;
				}
			}
		}
	}
	.shape{
		z-index: 3;
		position: absolute;
		top: 0;
		left: 0;
		width: 20.5%;
		height: 116%;
		background-position: left top;
		background-repeat: no-repeat;
		background-size: contain;
		background-image: url('./../img/top_sape.png');
		@media only screen and (max-width: 1290px) {
			display: none;

		}
	}
}

.flats{
	padding-bottom: 30px;
	div.flats_image{
		width: 100%;
		height: 0;
		padding-bottom: 26%;
	}
	div.flat_name{
		@media only screen and (max-width: 991px) {
			text-align: center;
			margin-bottom: 10px;
		}
		header{
			margin-bottom: 15px;
			div.default_font{
				font-weight: 700;
				h1,h2,h3,h4,h5,h6{
					font-weight: inherit;
					&:before{
						color: $orange;
						content:'"';
					}
					&:after{
						color: $orange;
						content:'"';
					}
				}
			}
		}
	}
	div.availability{
		@media only screen and (max-width: 991px) {
			text-align: center;
			margin-bottom: 10px;
		}
		ul{
			display: flex;
			flex-flow: row;
			@media only screen and (max-width: 991px) {
				display: block;
			}
			li{
				display: inline-block;
				@media only screen and (max-width: 991px) {
					width: 100%;
					margin: 10px 0;
				}
				&:first-child{
					font-size: 11px;
					line-height: 13px;
					order: 2;
					padding-left: 12px;
					color: $font_color2;
					font-weight: 600;
					p{
						font-weight: inherit;
					}
				}
				&:last-child{
					
					order: 1;
					h2{
						font-size: 24px;
						line-height: 24px;
						span{
							font-size: 55px;
							color: #ce9250;
						}
					}
				}
			}
		}
	}
	div.text_desc{
		text-align: right;
		div.default_font{
			text-align: left;
			padding-left: 30px;
			@media only screen and (max-width: 991px) {
				padding-left: 0;
				text-align: center;
			}
		}
		a.look_more{
			color: $font_grey;
		}
	}
	div.flats_image{
		margin-top: 50px;
		width: 100%;
		height: 0;
		background-position: center bottom;
		background-repeat: no-repeat;
		background-size: cover;
		padding-bottom: 26%;
		position: relative;
		@media only screen and (max-width: 1200px) {
			margin-top: 25px;
		}
		@media only screen and (max-width: 991px) {
			padding-bottom: 255px;
		}
		.cover{
			@include fill;
			background: rgba(24,24,24,0);
			background: -moz-linear-gradient(top, rgba(24,24,24,0) 50%, rgba(24,24,24,1) 100%);
			background: -webkit-gradient(left top, left bottom, color-stop(50%, rgba(24,24,24,0)), color-stop(100%, rgba(24,24,24,1)));
			background: -webkit-linear-gradient(top, rgba(24,24,24,0) 50%, rgba(24,24,24,1) 100%);
			background: -o-linear-gradient(top, rgba(24,24,24,0) 50%, rgba(24,24,24,1) 100%);
			background: -ms-linear-gradient(top, rgba(24,24,24,0) 50%, rgba(24,24,24,1) 100%);
			background: linear-gradient(to bottom, rgba(24,24,24,0) 50%, rgba(24,24,24,1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#181818', endColorstr='#181818', GradientType=0 );
			.more{
				position: absolute;
				left: 0;
				bottom: 30px;
				text-align: center;
				width: 100%;
			}
		}
	}
	.flat_container{
		margin-bottom: 80px;
		@media only screen and (max-width: 1500px) {
			margin-bottom: 40px;
		}
		@media only screen and (max-width: 1200px) {
			margin-bottom: 25px;
		}
	}
}

footer.footer{
	div.footer_top{
		div.rules{
			padding-top: 15px;
			> ul{
				> li{
					padding-left: 0;
					padding-top: 0;
					margin-bottom: 15px;
					> div{
						display: inline-block;
						vertical-align: top;
						&.box{
							cursor: pointer;
							position: relative;
							width: 30px;
							height: 0;
							padding-bottom: 30px;
							background: #f1f1f1;
							> div{
								@include fill;
								background-repeat: no-repeat;
								background-position: center;
								&.yes{
									&.on{
										background-image: url('./../img/check_icon1.png');
									}
								}
							}
						}
						&.default_font{
							width: calc(100% - 30px);
							padding-left: 15px;
							text-align: left;
							color: $white;
							a{
								color:$orange;
							}
						}
					}
				}
			}
		}
		padding: 90px 0;
		@media only screen and (max-width: 1500px) {
			padding: 45px 0;
		}
	}
	div.footer_bottom{
		border-top: 1px solid #373148;
		color: $font_color2;
		padding: 40px;
		@media only screen and (max-width: 991px) {
			text-align: center;
		}
		div.default_font{
			margin-bottom: 25px;
			color: $font_color2;
		}
		.to_up{
			font-size: 11px;
			line-height: 11px;
			font-weight: 700;
			color: #626172;
			cursor: pointer;
		}
	}
	background-position: left top;
	background-repeat: no-repeat;
	background-size: cover;
	background-image: url('./../img/footer_bg.jpg');
	
	header.footer_header{
		padding-bottom: 26px;
		div.default_font{
			font-size: 15px;
			line-height: 40px;
			color: $font_color2;
			h2{
				color: $orange2;
				font-weight: 800;
			}
		}
	}
	div.holder{
		width: 100%;
		position: relative;
		margin-bottom: 46px;
		border-bottom: 2px solid #515065;
		::-webkit-input-placeholder { /* Chrome/Opera/Safari */
			color: $white;
		  }
		  ::-moz-placeholder { /* Firefox 19+ */
			color: $white;
		  }
		  :-ms-input-placeholder { /* IE 10+ */
			color: $white;
		  }
		  :-moz-placeholder { /* Firefox 18- */
			color: $white;
		  }
		input.input{
			width: calc(100% - 93px);
			height: 60px;
			background-color: transparent;
			border:none;
			background: transparent;
			display: inline-block;
			vertical-align: middle;
			font-family: $main_font;
			font-size: 21px;
			color: $white;
			outline: none;
		}
		input.submit{
			font-size: 13px;
			line-height: 13px;
			text-transform: uppercase;
			color: $white;
			height: 30px;
			background-repeat: no-repeat;
			background-position: right center;
			background-image: url('./../img/news_more_arr.png');
			width: 90px;
			padding-right: 32px;
			border:none;
			background-color: transparent;
			font-weight: 800;
		}
	}
	nav.menu{
		> ul{
			> li{
				margin-right: 40px;
				@media only screen and (max-width: 991px) {
					margin-right: 0;
					margin-bottom: 20px;
					width: 100%;
					text-align: center;
				}
				 a{
					font-size: 11px;
					color: #626172;
				}
				&:last-child{
					a{
						margin-right: 0;
						padding-right: 0;
					}
				}
			}
		}
	}
}

section.top{
	height: 337px;
	width: 100%;
	background-repeat: no-repeat;
	background-position: left top;
	background-size: cover;
	position: relative;
	@media only screen and (max-width: 1500px) {
		height: 237px;
	}
	header.page_header{
		padding-top: 200px;
		@media only screen and (max-width: 1500px) {
			padding-top: 150px;
		}
		@media only screen and (max-width: 1700px) {
			padding-left: 70px;
		}
		@media only screen and (max-width: 1600px) {
			padding-left: 60px;
		}
		@media only screen and (max-width: 1290px) {
			padding-left: 0;
		}
		@media only screen and (max-width: 991px) {
			padding-left: 0;
			text-align: center;
		}
		div.default_font{
			h1{
				font-weight: 800;
			}
		}
	}
	div.default_font{
		position: relative;
		z-index: 10;
		color: $white;
		font-family: $main_font;
	}
	div.shape{
		width: 5.2%;
		height: 438px;
		position: absolute;
		top: 0;
		left: 0;
		background-repeat: no-repeat;
		background-position: right top;
		background-image: url('./../img/top_shape.png');
		background-size: auto 100%;
		z-index: 2;
		@media only screen and (max-width: 1500px) {
			height: 325px;
			width: 73px;
		}
		@media only screen and (max-width: 1290px) {
			display: none;
		}
	}
}

header.section_header{
	margin: 80px 0;
	@media only screen and (max-width: 1500px) {
		margin: 40px 0;
	}
	@media only screen and (max-width: 1200px) {
		margin: 25px 0;
	}
	div.default_font{
		text-align: center;
		position: relative;
		font-weight: 800;
		font-size: 17px;
		h1,h2,h3,h4,h5,h6{
			font-weight: inherit;
		}
	}
}

section.flats2{
	&:nth-child(odd){
		background-color: #f8f8f8;
	}
	&:nth-child(even){
		background-color: $white;
	}
	padding-top: 3%;
	padding-bottom: 3%;
	div.flats2_content{
		//min-height: 500px;
		width: 100%;
		background-repeat: no-repeat;
		background-position: center bottom;
		background-size: 100% auto;
		position: relative;
		margin-bottom: 50px;
		&:last-child{
			margin-bottom: 0;
		}
		/*article.flats_box{
			padding: 70px;
			background-color: rgba(255,255,255,0.9);
		}*/
	}
	div.info{
		//margin-bottom: 30px;
		margin-right: 30px;
	}
	div.flat_logo{
		text-align: right;
		margin-bottom: 10px;
	}
	div.availability{
		ul{
			text-align: right;
			li{
				display: inline-block;
				&:first-child{
					font-size: 12px;
					line-height: 15px;
				}
				&:last-child{
					padding-left: 12px;
					h2{
						font-size: 28px;
						strong{
							font-size: 55px;
							color: #ce9250;
						}
					}
				}
			}
		}
	}
	div.text_desc{
		text-align: right;
		div.default_font{
			text-align: left;
			padding-bottom: 30px;
		}
		a.look_more{
			color: $font_grey;
		}
	}
	div.flats_image{
		width: 100%;
		height: 0;
		background-position: center bottom;
		background-repeat: no-repeat;
		background-size: 100% auto;
		padding-bottom: 26%;
	}
}

section.details_desc{
	background: $white;
	padding: 60px 0;
	@media only screen and (max-width: 1500px) {
		padding: 30px 0;
	}
	@media only screen and (max-width: 1200px) {
		padding: 25px 0;
	}
	@media only screen and (max-width: 991px) {
		text-align: center;
	}
	> div.container{
		> div.double{
			margin: 0 -80px;
			> div.col-md-6{
				margin-top: -20px;
				padding: 0 80px;
				@media only screen and (max-width: 991px) {
					margin-top: 0;
				}
			}
		}
	}
	div.flat_name{
		header{
			margin-bottom: 15px;
			div.default_font{
				font-weight: 700;
				h1,h2,h3,h4,h5,h6{
					font-weight: inherit;
					&:before{
						color: $orange;
						content:'"';
					}
					&:after{
						color: $orange;
						content:'"';
					}
				}
			}
		}
	}
	.flats_image{
		width: 100%;
		height: 0;
		padding-bottom: 54%;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		@media only screen and (max-width: 991px) {
			margin-top: 25px;
		}
	}
	div.text_desc{
		&.desc_top{
			border-bottom: 1px solid #f2f2f2;
			margin-bottom: 50px;
			@media only screen and (max-width: 991px) {
				margin-bottom: 90px;
			}
			.default_font{
				font-size: 15px;
				padding-bottom: 50px;
			}
			
		}
		div.default_font{
			text-align: justify;
		}
	}
	.in_features{
		ul{
			margin-bottom: 10px;
			li{
				display: inline-block;
				vertical-align: top;
				width: 48%;
				margin-right: 4%;
				padding-left: 77px;
				background-repeat: no-repeat;
				background-position: left top;
				margin-bottom: 40px;
				@media only screen and (max-width: 991px) {
					width: 100%;
					margin-right: 0;
					padding-left: 0;
					padding-top: 77px;
					background-position: center top;
					text-align: center;
				}
				header{
					font-size: 24px;
					line-height: 34px;
					margin-bottom: 10px;
					font-weight: 700;
					p{
						font-weight: inherit;
					}
				}
				&:nth-child(2n), &:last-child{
					margin-right: 0;
				}
			}
		}
		.bottom_desc{
			text-align: center;
		}
	}
}
.logo1{
	margin-bottom: -50px;
	width: 100px;
	height: 0;
	padding-bottom: 100px;
	margin-left: auto;
	margin-right: auto;
	border-radius: 50%;
	background-color: #f2f2f2;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url('./../img/logo123.png');
	&.w{
		background-color: $white;
		margin: 0;
	}
}
.text-left{
	text-align: left !important;
	@media only screen and (max-width: 991px) {
		text-align: center !important;
	}
}

.text-right{
	@media only screen and (max-width: 991px) {
		text-align: center;
	}
}

.banner{
	padding: 55px 0;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	text-align: center;
	color: $white;
	ul{
		li{
			display: inline-block;
			vertical-align: middle;
			@media only screen and (max-width: 991px) {
				width: 100%;
				margin-bottom: 20px;
				.w{
					margin-left: auto;
					margin-right: auto;
				}
				&:last-child{
					margin-bottom: 0;
				}
			}
		}
	}
	.default_font{
		padding-left: 30px;
		@media only screen and (max-width: 991px) {
			padding-left: 0;
		}
		h1,h2,h3,h4,h5,h6{
			color: $white;
			font-weight: 800;
		}
	}
}

section.flats_table{
	table{
		width: 100%;
		thead{
			background-color: $orange;
			> tr{
				>th{
					border-right: 1px solid $white;
					&:last-child{
						border-right: none;
					}
					p{
						color: $white;
					}
				}
			}
		}
		tr{
			th,td{
				text-align: center;
				a,p{
					font-size: 16px;
					line-height: 22px;
					padding: 13px 0;
					display: inline-block;
					color: #73717c;
				}
			}
		}
		tbody{
			> tr{
				&:nth-child(odd){
					background-color: #f4f4f4;
				}
				&:nth-child(even){
					background-color: #e7e7e7;
				}
				> td{
					border-right: 1px solid $white;
					&:last-child{
						border-right: none;
					}
					p{
						
					}
				}
				td.view{
					> a{
						padding-right: 38px;
						color: $dark;
						background-position: right center;
						background-repeat: no-repeat;
						background-image: url('./../img/t_icon1.png');
					}
				}
				td.status_red{
					> p{
						color: #ce3333;
					}
				}
				td.status_orange{
					> p{
						color: #ffc000;
					}
				}
				td.status_green{
					> p{
						color: #80c750;
					}
				}
				td.look{
					> a{
						padding-right: 32px;
						background-position: right center;
						background-repeat: no-repeat;
						background-image: url('./../img/t_icon3.png');
					}
				}
			}
		}
	}
	div.text_desc{
		padding: 5% 0;
	}
}

section.galery{
	background: $grey;
	padding-bottom: 60px;
	@media only screen and (max-width: 1500px) {
		padding-bottom: 30px;
	}
	@media only screen and (max-width: 1200px) {
		padding-bottom: 25px;
	}
	.container{
		> .row{
			margin: 0 -10px;
			> .col-md-3{
				padding: 0 10px;
			}
		}
	}
	div.image{
		width: 100%;
		height: 0;
		padding-bottom: 80%;
		margin-bottom: 20px;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		position: relative;
	}
}

section.news{
	padding-bottom: 3%;
	article.article{
		margin-bottom: 50px;
		header.article_header{
			padding-bottom: 20px;
			div.default_font{
				p{
					color: #848484;
					padding-bottom: 5px;
				}
				h6{
					color: $dark;
					font-family: $main_font;
				}
			}
		}
		div.text_desc{
			div.default_font{
				line-height: 30px;
			}
		}
	}
}

section.contact{
	background: $white;
	padding-bottom: 40px;
	@media only screen and (max-width: 1500px) {
		padding-bottom: 25px;
	}
	div.row{
		> div.col-md-6{
			&:first-child{
				> div.contact_box{
					padding-right: 25px;
				}
			}
			&:last-child{
				> div.contact_box{
					padding-left: 25px;
				}
			}
		}
	}
	header{
		margin-bottom: 40px;
	}
	div.default_font{
		color: $dark;
	}
	form{
		div.rules{
			padding-top: 15px;
			> ul{
				> li{
					padding-left: 0;
					padding-top: 0;
					margin-bottom: 15px;
					> div{
						display: inline-block;
						vertical-align: top;
						&.box{
							cursor: pointer;
							position: relative;
							width: 30px;
							height: 0;
							padding-bottom: 30px;
							background: #f1f1f1;
							> div{
								@include fill;
								background-repeat: no-repeat;
								background-position: center;
								&.yes{
									&.on{
										background-image: url('./../img/check_icon1.png');
									}
								}
							}
						}
						&.default_font{
							width: calc(100% - 30px);
							padding-left: 15px;
							text-align: left;
							a{
								color:$orange;
							}
						}
					}
				}
			}
		}
		div.holder{
			margin-bottom: 15px;
			input.input{
				width: 100%;
				height: 60px;
				outline: none;
				color: $dark;
				border: none;
				font-size: 14px;
				background-color: #f1f1f1;
				font-family: $main_font;
				padding:0 30px;
			}
			textarea.textarea{
				width: 100%;
				height: 200px;
				outline: none;
				color: $dark;
				border: none;
				font-size: 14px;
				background-color: #f1f1f1;
				font-family: $main_font;
				padding:15px 30px;
				resize: none;
			}
			input.submit{
				margin-top: 25px;
				font-size: 13px;
				line-height: 13px;
				text-transform: uppercase;
				color: $dark;
				height: 30px;
				background-repeat: no-repeat;
				background-position: right center;
				background-image: url(./../img/news_more_arr.png);
				width: 190px;
				padding-right: 32px;
				border: none;
				background-color: transparent;
				font-weight: 800;
			}
			::-webkit-input-placeholder { /* Chrome/Opera/Safari */
			color: $dark;
			font-size: 14px;
			font-family: $main_font;
			}
			::-moz-placeholder { /* Firefox 19+ */
			color: $dark;
			font-size: 14px;
			font-family: $main_font;
			}
			:-ms-input-placeholder { /* IE 10+ */
			color: $dark;
			font-size: 14px;
			font-family: $main_font;
			}
			:-moz-placeholder { /* Firefox 18- */
			color: $dark;
			font-size: 14px;
			font-family: $main_font;
			}
		}
	}
	div.contact_box{
		ul{
			li{
				min-height: 32px;
				padding-left: 47px;
				font-weight: 600;
				color: $dark;
				font-size: 15px;
				line-height: 30px;
				background-repeat: no-repeat;
				background-position: left top;
				margin-bottom: 20px;
				@media only screen and (max-width: 991px) {
					background-position: center top;
					padding-top: 47px;
					text-align: center;
					padding-left: 0;
				}
				a{
					color: $dark;
				}
				&.adres{
					background-image: url('./../img/c_icon1.png');
					background-position: left 5px top;
					@media only screen and (max-width: 991px) {
						background-position: center top;
						padding-top: 47px;
						text-align: center;
						padding-left: 0;
					}
				}
				&.phone{
					background-image: url('./../img/c_icon2.png');
					background-position: left 8px top;
					@media only screen and (max-width: 991px) {
						background-position: center top;
						padding-top: 47px;
						text-align: center;
						padding-left: 0;
					}
				}
				&.mail{
					background-image: url('./../img/c_icon3.png');
				}
				p{
					font-weight: inherit;
				}
			}
		}
	}
	div.text_desc{
		margin: 15px 0;
		div.default_font{
			line-height: 25px;
		}
	}
}

section.map{
	position: relative;
	width: 100%;
	height: 0;
	padding-bottom: 27.9%;
	> iframe{
		@include fill;
	}
}

header.header_active{
	background-color: $dark;
	border-bottom: 2px solid $orange;
}

div.dark_cover{
	position: absolute;
	top: 0;
	left: 0;
	height: 90%;
	width: 100%;
	background-color: rgba(0,0,0,0.2);
}

section.content_top{
	padding-top: 3%;
	background-color: #f8f8f8;
	header.realization_header{
		div.text_desc{
			div.default_font{
				h1,h2,h3,h4,h5,h6{
					margin-bottom: 10px;
				}
			}
		}
	}	
}

section.about_features{
	padding-bottom: 40px;
	div.about_box{
		div.icon{
			margin-bottom: 50px;
		}
	}
	.features_list{
		font-size: 15px;
		line-height: 24px;
		font-weight: 600;
		color: $dark;
		p{
			font-weight: inherit;
		}
		ol{
			counter-reset: item;
			margin-left: 0;
			padding-left: 0;
			li{
				margin-bottom: 40px;
				padding-top: 10px;
				width: 48%;
				margin-right: 2%;
				display: inline-block;
				vertical-align: top;
				padding-left: 66px;
				min-height: 43px;
				position: relative;
				@media only screen and (max-width: 991px) {
					margin-right: 0;
					width: 100%;
					margin-bottom: 20px;
				}
				&:before{
					position: absolute;
					top: 0;
					left: 0;
					display: inline-block;
					content: counter(item);
					counter-increment: item;
					width: 43px;
					height: 0;
					padding-bottom: 43px;
					border-radius: 50%;
					color: $white;
					text-align: center;
					line-height: 43px;
					font-size: 20px;
					font-weight: 700;
					background: rgba(247,197,18,1);
					background: -moz-linear-gradient(top, rgba(247,197,18,1) 0%, rgba(249,103,6,1) 100%);
					background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(247,197,18,1)), color-stop(100%, rgba(249,103,6,1)));
					background: -webkit-linear-gradient(top, rgba(247,197,18,1) 0%, rgba(249,103,6,1) 100%);
					background: -o-linear-gradient(top, rgba(247,197,18,1) 0%, rgba(249,103,6,1) 100%);
					background: -ms-linear-gradient(top, rgba(247,197,18,1) 0%, rgba(249,103,6,1) 100%);
					background: linear-gradient(to bottom, rgba(247,197,18,1) 0%, rgba(249,103,6,1) 100%);
					filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7c512', endColorstr='#f96706', GradientType=0 );
				}
				&:nth-child(2n){
					margin-right: 0;
				}
			}
		}
	}
}

section.news_details{
	padding-bottom: 20px;
	padding-top: 20px;
	header.news_header_top{
		div.default_font{
			h1,h2,h3,h4,h5,h6{
				font-family: $main_font;
				color: #faa61a;
			}
		}
	}
	div.news_details_image{
		margin: 30px 0;
		height: 300px;
		width: 100%;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
	}
	a.return_news{
		margin-top: 20px;
		display: inline-block;
		font-size: 18px;
		font-weight: 500;
		font-family: $main_font;
		color: #faa61a;
	}
}

section.home_news{
	&.main_news{
		padding-top: 80px;
	}
	padding-bottom: 40px;
	background: $grey;
	header.section_header{
		div.default_font{
			padding-bottom: 0;
			&:after{
				display: none;
			}
		}
	}
	.text-center{
		margin-top: 40px;
		margin-bottom: 40px;
		@media only screen and (max-width: 1500px) {
			margin-top: 25px;
			margin-bottom: 25px;
		}
		@media only screen and (max-width: 991px) {
			margin: 15px 0;
		}
	}
	div.news_list{
		ul{
			li{
				position: relative;
				width: 48%;
				margin-right: 4%;
				display: inline-block;
				vertical-align: top;
				margin-bottom: 40px;
				&:nth-child(2n){
					margin-right: 0;
				}
				@media only screen and (max-width: 991px) {
					width: 100%;
					margin-bottom: 20px;
				}
				div.image{
					//height: 300px;
					height: 255px;
					width: 100%;
					background-repeat: no-repeat;
					background-position: center;
					background-size: cover;
					margin-bottom: 30px;
				}
				div.desc{
					header.news_header{
						padding-bottom: 20px;
						margin-bottom: 20px;
						border-bottom: 1px solid $orange2;
						div.default_font{
							font-size: 17px;
							line-height: 27px;
						}
						div.date{
							padding-top: 10px;
							color: #929196;
							font-size: 11px;
							line-height: 11px;
							font-weight: 700;
							p{
								font-weight: inherit;
							}
						}
					}
					div.text_desc{
						div.default_font{
							font-size: 15px;
							line-height: 25px;
						}
					}
				}
			}
		}
	}
	a.more_news_button{
		display: inline-block;
		padding-top: 6px;
		padding-bottom: 6px;
		font-size: 18px;
		font-weight: light;
		padding-right: 55px;
		background-repeat: no-repeat;
		background-position: center right;
		background-image: url('./../img/news_more_arr.png');
		color: $dark;
	}
}

section.location{
	padding-bottom: 80px;
	div.text_desc{
		margin-bottom: 5%;
	}
	div.location_map{
		position: relative;
		height: 320px;
		width: 100%;
		> iframe{
			@include fill;
		}
	}
}

section.promotions{
	margin-bottom: 80px;
	div.promotions_list{
		ul{
			li{
				div.main_image{
					width: 100%;
					height: 0;
					padding-bottom: 60%;
					position: relative;
					margin-bottom: 15px;
				}
				div.image{
					@include fill;
					background-repeat: no-repeat;
					background-position: center;
					background-size: cover;
				}
				div.thumbnails_carousel{
					width: 100%;
					position: relative;
					div.thumbnails{
						overflow: hidden;
						width: 80%;
						margin-left: 10%;
						ul{
							white-space: nowrap;
							position: relative;
							li{
								width: 33%;
								height: 0;
								padding-bottom: 20%;
								display: inline-block;
								vertical-align: middle;
								position: relative;
								//margin-right: 3.5%;
								div.image{
									width: 90%;
									left: 5%;
								}
							}
						}
						div.arr_left{
							width: 10%;
							height: 100%;
							position: absolute;
							top: 0;
							left: 0;
							background-repeat: no-repeat;
							background-position: center;
							background-image: url('./../img/promo_arr.png');
							transform: scaleX(-1);
							cursor: pointer;
						}
						div.arr_right{
							width: 10%;
							height: 100%;
							position: absolute;
							top: 0;
							right: 0;
							background-repeat: no-repeat;
							background-position: center;
							background-image: url('./../img/promo_arr.png');
							cursor: pointer;
						}
					}
				}
				div.price{
					margin-top: 10px;
					margin-bottom: 20px;
					color: $dark;
					font-size: 33px;
					line-height: 39px;
					font-family: $main_font;
					strong{
						color: $orange;
						font-weight: 900;
						font-family: $main_font;
						font-size: 55px;
						line-height: 61px;
					}
				}
				div.text_desc{
					div.default_font{
						text-align: justify;
					}
				}
			}
		}
	}
}